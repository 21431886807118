import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ProductCategoriesFacade } from '../facades/product-categories.facade';

export function productCategoriesCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const productCategoriesFacade = inject(ProductCategoriesFacade);
        productCategoriesFacade.clear();
        return true;
    };
}
