import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { catchError, map, of } from 'rxjs';
import { DefaultCategory, DefaultCategoryDto, ProductCategories, ProductCategoriesDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getProductCategories() {
        return this.httpClient.get<ApiResponse<ProductCategoriesDto>>(`api/v1/categories/items`).pipe(map((response) => new ProductCategories(response.data)));
    }

    getDefaultCategory() {
        return this.httpClient.get<ApiResponse<DefaultCategoryDto>>(`api/v1/category/default`).pipe(
            map((response) => new DefaultCategory(response.data)),
            catchError(() => of(new DefaultCategory({ category_id: '' }))),
        );
    }

    getCategoryImage(subcode: string) {
        return this.httpClient.addResponseCaching().get(`api/v1/categories/items/${subcode}`, { responseType: 'blob' });
    }
}
