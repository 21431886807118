import { Option } from '@app/shared/forms/form-tree-dropdown';
import { ProductCategoryDto } from './product-category.dto';

export class ProductCategory implements Option {
    id: string;
    code: string;
    parentCode: string;
    description: string;
    presentationOrder: number;

    constructor(productCategoryDto: ProductCategoryDto) {
        this.id = productCategoryDto.id;
        this.code = productCategoryDto.code;
        this.parentCode = productCategoryDto.parent_category;
        this.description = productCategoryDto.description;
        this.presentationOrder = productCategoryDto.presentation_order;
    }
}
