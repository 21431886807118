import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductCategoriesState } from '../reducers/product-categories.reducer';
import { PRODUCT_CATEGORIES_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<ProductCategoriesState>(PRODUCT_CATEGORIES_FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: ProductCategoriesState) => state.loaded);
export const selectCategories = createSelector(selectState, (state: ProductCategoriesState) => state.categories);
export const selectOtherCategories = createSelector(selectState, (state: ProductCategoriesState) => state.others);

export const selectDefaultCategoryId = createSelector(selectState, (state: ProductCategoriesState) => state.defaultCategoryId);
export const selectDefaultCategoryLoaded = createSelector(selectState, (state: ProductCategoriesState) => state.defaultCategoryLoaded);

export const selectImageUrl = (subcode: string) =>
    createSelector(selectState, (state: ProductCategoriesState) => {
        const result = state.images.get(subcode);

        return result?.blob ? URL.createObjectURL(result.blob) : null;
    });

export const selectImageLoaded = (subcode: string) =>
    createSelector(selectState, (state: ProductCategoriesState) => {
        const result = state.images.get(subcode);

        return result ? result.loaded : null;
    });
