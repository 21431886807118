import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PRODUCT_CATEGORIES_FEATURE_KEY } from './keys';
import { productCategoriesReducer } from './reducers/product-categories.reducer';
import { ProductCategoriesEffects } from './effects/product-categories.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(PRODUCT_CATEGORIES_FEATURE_KEY, productCategoriesReducer),
        EffectsModule.forFeature([ProductCategoriesEffects]),
    ],
})
export class ProductCategoriesStoreModule {}
