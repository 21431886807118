import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectCategories,
    selectLoaded,
    selectOtherCategories,
    selectImageUrl,
    selectImageLoaded,
    selectDefaultCategoryId,
    selectDefaultCategoryLoaded,
} from '../selectors/product-categories.selector';
import { productCategoriesActions } from '../actions/product-categories.actions';

@Injectable({
    providedIn: 'root',
})
export class ProductCategoriesFacade {
    private readonly store = inject(Store);

    defaultCategoryLoaded$ = this.store.select(selectDefaultCategoryLoaded);
    defaultCategoryId$ = this.store.select(selectDefaultCategoryId);

    loaded$ = this.store.select(selectLoaded);
    categories$ = this.store.select(selectCategories);
    otherCategories$ = this.store.select(selectOtherCategories);

    imageUrl$ = (subcode: string) => this.store.select(selectImageUrl(subcode));
    imageLoaded$ = (subcode: string) => this.store.select(selectImageLoaded(subcode));

    getDefaultCategory(): void {
        this.store.dispatch(productCategoriesActions.getDefaultCategory());
    }

    getProductCategories(): void {
        this.store.dispatch(productCategoriesActions.getProductCategories());
    }

    getCategoryImage(subcode: string): void {
        this.store.dispatch(productCategoriesActions.getCategoryImage({ subcode }));
    }

    clear(): void {
        this.store.dispatch(productCategoriesActions.clear());
    }
}
