import { createReducer, on } from '@ngrx/store';
import { productCategoriesActions } from '../actions/product-categories.actions';
import { ProductCategory } from '../models';

interface ImageEntry {
    loaded: null | boolean;
    blob: null | Blob;
}

export interface ProductCategoriesState {
    loaded: boolean | null;
    categories: ProductCategory[];
    others: ProductCategory[];

    images: Map<string, ImageEntry>;

    defaultCategoryId: string | null;
    defaultCategoryLoaded: boolean | null;
}

export const initialState: ProductCategoriesState = {
    loaded: null,
    categories: [],
    others: [],

    images: new Map(),

    defaultCategoryId: null,
    defaultCategoryLoaded: null,
};

export const productCategoriesReducer = createReducer(
    initialState,

    // Get default category
    on(
        productCategoriesActions.getDefaultCategory,
        (state): ProductCategoriesState => ({
            ...state,
            defaultCategoryId: null,
            defaultCategoryLoaded: null,
        }),
    ),
    on(
        productCategoriesActions.getDefaultCategorySuccess,
        (state, { defaultCategory }): ProductCategoriesState => ({
            ...state,
            defaultCategoryId: defaultCategory.categoryId,
            defaultCategoryLoaded: true,
        }),
    ),
    on(
        productCategoriesActions.getDefaultCategoryError,
        (state): ProductCategoriesState => ({
            ...state,
            defaultCategoryLoaded: false,
        }),
    ),

    // Get product categories
    on(
        productCategoriesActions.getProductCategoriesSuccess,
        (state, { categories }): ProductCategoriesState => ({
            ...state,
            loaded: true,
            categories: categories.categories,
            others: categories.others,
        }),
    ),
    on(
        productCategoriesActions.getProductCategoriesError,
        (state): ProductCategoriesState => ({
            ...state,
            loaded: false,
        }),
    ),

    // Get category image
    on(productCategoriesActions.getCategoryImage, (state, payload): ProductCategoriesState => {
        const images = new Map(state.images);
        images.set(payload.subcode, { blob: null, loaded: null });

        return {
            ...state,
            images,
        };
    }),

    on(productCategoriesActions.getCategoryImageSuccess, (state, payload): ProductCategoriesState => {
        const images = new Map(state.images);
        images.set(payload.subcode, { blob: payload.blob, loaded: true });

        return {
            ...state,
            images,
        };
    }),

    on(productCategoriesActions.getCategoryImageError, (state, payload): ProductCategoriesState => {
        const images = new Map(state.images);
        images.set(payload.subcode, { blob: null, loaded: false });

        return {
            ...state,
            images,
        };
    }),
    on(productCategoriesActions.clear, (): ProductCategoriesState => ({ ...initialState })),
);
